.header {
  height: 50px;
  background-color: #ffffff;
}
.header .header-top {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  text-align: right;
}
.header .header-top .navStatus {
  text-align: left;
}
.header .header-top .navStatus .navStatusButton {
  margin-bottom: 16px;
  text-align: left;
}
.header .header-top .logo {
  line-height: 50px;
  text-align: left;
  font-size: 18px;
}
.header .header-top .logo img {
  height: 40px;
  vertical-align: middle;
}
.header .header-top .logo span {
  margin-left: 5px;
}
.header .header-top .weather .userName {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 240px;
  float: right;
  margin-right: 15px;
}
.header .header-top .weather .date {
  display: inline-block;
  min-width: 130px;
  float: right;
}
.header .header-top .weather .logout {
  margin-left: 15px;
}
.header .header-top .weather .setting {
  margin-left: 15px;
  font-size: 18px;
  float: right;
  margin-top: 15px;
}
.header .header-top .weather .setting:hover {
  color: #1890FF;
}
.header .header-top .weather:after {
  content: '';
  display: block;
  clear: both;
}
.simple-page .header-top {
  background: #1890ff;
  color: #ffffff;
}
.settingDropDown .ant-dropdown-menu {
  margin-top: 12px;
}
.settingDropDown .MenuItemIcon {
  margin-left: 10px;
}
.settingDropDown .MenuItemI {
  margin-left: -3px;
}

.container .nav-left {
  width: 240px;
  min-height: 240px;
}
.container .nav-left .logo {
  line-height: 90px;
  background-color: #002140;
  text-align: left;
  padding-left: 18px;
}
.container .nav-left .logo .logoInfo {
  font-size: 18px;
}
.container .nav-left .logo img {
  width: 42px;
}
.container .nav-left .logo h1 {
  color: #ffffff;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;
}
.container .nav-left-shrink {
  width: 80px!important;
}
.container .main-expand {
  width: calc(100% - 80px);
}
.container .menuLeft.ant-menu.ant-menu-dark.ant-menu-inline-collapsed {
  height: calc(100vh - 90px);
}
.container .menuLeft.ant-menu.ant-menu-dark {
  height: calc(100vh - 95px);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.container .menuLeft.ant-menu.ant-menu-dark::-webkit-scrollbar {
  display: none;
}

.ty-table,
.institution,
.school,
.classes,
.sysConfig,
.applicationGroup,
.application,
.userMan,
.privilegeClass,
.privilegeRole,
.privilegeRoleGroup,
.privilegeMan,
.associatedUsers,
.classTeacherUser,
.resourceMan,
.resourceClass,
.courseClass,
.subjectMan,
.testPaperMan,
.courseMan,
.statistics {
  background-color: #fff;
}
.ty-table .institutionTable .ant-table-thead,
.institution .institutionTable .ant-table-thead,
.school .institutionTable .ant-table-thead,
.classes .institutionTable .ant-table-thead,
.sysConfig .institutionTable .ant-table-thead,
.applicationGroup .institutionTable .ant-table-thead,
.application .institutionTable .ant-table-thead,
.userMan .institutionTable .ant-table-thead,
.privilegeClass .institutionTable .ant-table-thead,
.privilegeRole .institutionTable .ant-table-thead,
.privilegeRoleGroup .institutionTable .ant-table-thead,
.privilegeMan .institutionTable .ant-table-thead,
.associatedUsers .institutionTable .ant-table-thead,
.classTeacherUser .institutionTable .ant-table-thead,
.resourceMan .institutionTable .ant-table-thead,
.resourceClass .institutionTable .ant-table-thead,
.courseClass .institutionTable .ant-table-thead,
.subjectMan .institutionTable .ant-table-thead,
.testPaperMan .institutionTable .ant-table-thead,
.courseMan .institutionTable .ant-table-thead,
.statistics .institutionTable .ant-table-thead {
  background-color: #eeeeee;
}
.ty-table .searchForm,
.institution .searchForm,
.school .searchForm,
.classes .searchForm,
.sysConfig .searchForm,
.applicationGroup .searchForm,
.application .searchForm,
.userMan .searchForm,
.privilegeClass .searchForm,
.privilegeRole .searchForm,
.privilegeRoleGroup .searchForm,
.privilegeMan .searchForm,
.associatedUsers .searchForm,
.classTeacherUser .searchForm,
.resourceMan .searchForm,
.resourceClass .searchForm,
.courseClass .searchForm,
.subjectMan .searchForm,
.testPaperMan .searchForm,
.courseMan .searchForm,
.statistics .searchForm {
  padding: 8px 0;
  margin-left: 12px;
}
.ty-table .searchForm .formSelect,
.institution .searchForm .formSelect,
.school .searchForm .formSelect,
.classes .searchForm .formSelect,
.sysConfig .searchForm .formSelect,
.applicationGroup .searchForm .formSelect,
.application .searchForm .formSelect,
.userMan .searchForm .formSelect,
.privilegeClass .searchForm .formSelect,
.privilegeRole .searchForm .formSelect,
.privilegeRoleGroup .searchForm .formSelect,
.privilegeMan .searchForm .formSelect,
.associatedUsers .searchForm .formSelect,
.classTeacherUser .searchForm .formSelect,
.resourceMan .searchForm .formSelect,
.resourceClass .searchForm .formSelect,
.courseClass .searchForm .formSelect,
.subjectMan .searchForm .formSelect,
.testPaperMan .searchForm .formSelect,
.courseMan .searchForm .formSelect,
.statistics .searchForm .formSelect {
  width: 240px;
}
.ty-table .searchForm .formInput,
.institution .searchForm .formInput,
.school .searchForm .formInput,
.classes .searchForm .formInput,
.sysConfig .searchForm .formInput,
.applicationGroup .searchForm .formInput,
.application .searchForm .formInput,
.userMan .searchForm .formInput,
.privilegeClass .searchForm .formInput,
.privilegeRole .searchForm .formInput,
.privilegeRoleGroup .searchForm .formInput,
.privilegeMan .searchForm .formInput,
.associatedUsers .searchForm .formInput,
.classTeacherUser .searchForm .formInput,
.resourceMan .searchForm .formInput,
.resourceClass .searchForm .formInput,
.courseClass .searchForm .formInput,
.subjectMan .searchForm .formInput,
.testPaperMan .searchForm .formInput,
.courseMan .searchForm .formInput,
.statistics .searchForm .formInput {
  width: 220px;
}
.ty-table .searchForm .formTreeSelect,
.institution .searchForm .formTreeSelect,
.school .searchForm .formTreeSelect,
.classes .searchForm .formTreeSelect,
.sysConfig .searchForm .formTreeSelect,
.applicationGroup .searchForm .formTreeSelect,
.application .searchForm .formTreeSelect,
.userMan .searchForm .formTreeSelect,
.privilegeClass .searchForm .formTreeSelect,
.privilegeRole .searchForm .formTreeSelect,
.privilegeRoleGroup .searchForm .formTreeSelect,
.privilegeMan .searchForm .formTreeSelect,
.associatedUsers .searchForm .formTreeSelect,
.classTeacherUser .searchForm .formTreeSelect,
.resourceMan .searchForm .formTreeSelect,
.resourceClass .searchForm .formTreeSelect,
.courseClass .searchForm .formTreeSelect,
.subjectMan .searchForm .formTreeSelect,
.testPaperMan .searchForm .formTreeSelect,
.courseMan .searchForm .formTreeSelect,
.statistics .searchForm .formTreeSelect {
  width: 240px;
}
.ty-table .searchForm .formButton .fromBtn,
.institution .searchForm .formButton .fromBtn,
.school .searchForm .formButton .fromBtn,
.classes .searchForm .formButton .fromBtn,
.sysConfig .searchForm .formButton .fromBtn,
.applicationGroup .searchForm .formButton .fromBtn,
.application .searchForm .formButton .fromBtn,
.userMan .searchForm .formButton .fromBtn,
.privilegeClass .searchForm .formButton .fromBtn,
.privilegeRole .searchForm .formButton .fromBtn,
.privilegeRoleGroup .searchForm .formButton .fromBtn,
.privilegeMan .searchForm .formButton .fromBtn,
.associatedUsers .searchForm .formButton .fromBtn,
.classTeacherUser .searchForm .formButton .fromBtn,
.resourceMan .searchForm .formButton .fromBtn,
.resourceClass .searchForm .formButton .fromBtn,
.courseClass .searchForm .formButton .fromBtn,
.subjectMan .searchForm .formButton .fromBtn,
.testPaperMan .searchForm .formButton .fromBtn,
.courseMan .searchForm .formButton .fromBtn,
.statistics .searchForm .formButton .fromBtn {
  margin-left: 10px;
}
.ty-table .searchForm .createBtn,
.institution .searchForm .createBtn,
.school .searchForm .createBtn,
.classes .searchForm .createBtn,
.sysConfig .searchForm .createBtn,
.applicationGroup .searchForm .createBtn,
.application .searchForm .createBtn,
.userMan .searchForm .createBtn,
.privilegeClass .searchForm .createBtn,
.privilegeRole .searchForm .createBtn,
.privilegeRoleGroup .searchForm .createBtn,
.privilegeMan .searchForm .createBtn,
.associatedUsers .searchForm .createBtn,
.classTeacherUser .searchForm .createBtn,
.resourceMan .searchForm .createBtn,
.resourceClass .searchForm .createBtn,
.courseClass .searchForm .createBtn,
.subjectMan .searchForm .createBtn,
.testPaperMan .searchForm .createBtn,
.courseMan .searchForm .createBtn,
.statistics .searchForm .createBtn {
  height: 30px;
}
.ty-table .searchForm .searchRightBtn,
.institution .searchForm .searchRightBtn,
.school .searchForm .searchRightBtn,
.classes .searchForm .searchRightBtn,
.sysConfig .searchForm .searchRightBtn,
.applicationGroup .searchForm .searchRightBtn,
.application .searchForm .searchRightBtn,
.userMan .searchForm .searchRightBtn,
.privilegeClass .searchForm .searchRightBtn,
.privilegeRole .searchForm .searchRightBtn,
.privilegeRoleGroup .searchForm .searchRightBtn,
.privilegeMan .searchForm .searchRightBtn,
.associatedUsers .searchForm .searchRightBtn,
.classTeacherUser .searchForm .searchRightBtn,
.resourceMan .searchForm .searchRightBtn,
.resourceClass .searchForm .searchRightBtn,
.courseClass .searchForm .searchRightBtn,
.subjectMan .searchForm .searchRightBtn,
.testPaperMan .searchForm .searchRightBtn,
.courseMan .searchForm .searchRightBtn,
.statistics .searchForm .searchRightBtn {
  margin-right: 15px;
}
.institutionModal .ant-modal-content .ant-modal-body,
.schoolModal .ant-modal-content .ant-modal-body,
.classesModal .ant-modal-content .ant-modal-body,
.sysConfigModal .ant-modal-content .ant-modal-body,
.applicationGroupModal .ant-modal-content .ant-modal-body,
.applicationModal .ant-modal-content .ant-modal-body,
.UserManModal .ant-modal-content .ant-modal-body,
.privilegeClassModal .ant-modal-content .ant-modal-body,
.privilegeRoleModal .ant-modal-content .ant-modal-body,
.privilegeManModal .ant-modal-content .ant-modal-body,
.privilegeRoleGroupModal .ant-modal-content .ant-modal-body,
.resourceManModal .ant-modal-content .ant-modal-body,
.resourceClassModal .ant-modal-content .ant-modal-body,
.courseClassModal .ant-modal-content .ant-modal-body,
.subjectManModal .ant-modal-content .ant-modal-body,
.testPaperManModal .ant-modal-content .ant-modal-body,
.courseModal .ant-modal-content .ant-modal-body {
  overflow-y: auto;
  max-height: 65vh;
  margin-right: 2px;
}
.associatedUsers .searchForm {
  margin-left: 0;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 16px!important;
}

* {
  margin: 0;
  padding: 0;
}
html {
  overflow: hidden;
}
ul,
li {
  list-style: none;
}
#dashboard-iframe {
  height: calc(100vh - 80px) !important;
}
.clearfix::after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}
.container {
  min-width: 1024px;
}
.container .nav-left {
  background-color: #001529;
  color: #ffffff;
  height: calc(100vh);
}
.container .main {
  height: calc(100vh);
  background-color: #f1f3f5;
  overflow: auto;
}
.container .content {
  position: relative;
  padding: 12px;
}
.content-wrap {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-top: -3px;
}
.content-wrap .ant-table-wrapper {
  margin-left: -1px;
  margin-right: -2px;
}
.ant-card button {
  margin-right: 10px;
}
::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
::-webkit-scrollbar-track {
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba(57, 65, 72, 0.57);
}

.tableActionIcon {
  color: rgba(0, 0, 0, 0.65);
  margin-right: 12px;
  cursor: pointer;
}
.tableActionIcon:hover {
  color: #1890ff;
}

@font-face {
  font-family: "trkj-iconfont"; /* Project id 1186012 */
  src: url(/static/media/iconfont.15541ddc.woff2) format('woff2'),
       url(/static/media/iconfont.c0e2a22a.woff) format('woff'),
       url(/static/media/iconfont.7ebb5d60.ttf) format('truetype');
}

.trkj-iconfont {
  font-family: "trkj-iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.trkj-jiechu:before {
  content: "\E614";
}

.trkj-bangding:before {
  content: "\E615";
}

.trkj-banjiguanli:before {
  content: "\E62D";
}

.trkj-withdraw:before {
  content: "\E603";
}

.trkj-fabu:before {
  content: "\E628";
}

.trkj-yonghu:before {
  content: "\E63F";
}

.trkj-kecheng:before {
  content: "\E609";
}

.trkj-ziyuan:before {
  content: "\E612";
}

.trkj-lianjieguanlian:before {
  content: "\E77C";
}

.trkj-shouquan:before {
  content: "\E613";
}

.trkj-xiugaimima01:before {
  content: "\E606";
}

.trkj-shanchu:before {
  content: "\E686";
}

.trkj-yanjing:before {
  content: "\E600";
}

.trkj-chakan:before {
  content: "\E6AD";
}

.trkj-xiugai:before {
  content: "\E627";
}


