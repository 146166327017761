
* {
  margin: 0;
  padding: 0;
}

html {
  overflow: hidden;
}

body {
}

ul, li {
  list-style: none;
}

// 仪表盘监控ifreme高度
#dashboard-iframe {
  height: calc(100vh - 80px) !important;
}

.clearfix {
  &::after {
    content: ' ';
    clear: both;
    display: block;
    visibility: hidden;
  }
}

.container {
  min-width: 1024px;
  .nav-left {
    background-color: #001529;
    color: #ffffff;
    height: calc(100vh);
  }

  .main {
    height: calc(100vh);
    background-color: #f1f3f5;
    overflow: auto;
  }

  .content {
    position: relative;
    padding: 12px;
  }
}

.content-wrap {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-top: -3px;

  .ant-table-wrapper {
    margin-left: -1px;
    margin-right: -2px;
  }
}

.ant-card {
  button {
    margin-right: 10px;
  }
}

// 全局滚动条
::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba(57, 65, 72, 0.57);
}

