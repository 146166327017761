.header{
    height: 50px;
    background-color: #ffffff;
    .header-top{
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        text-align: right;
        //border-bottom: 1px solid #40AEFC;
        .navStatus{
            text-align: left;
            .navStatusButton{
                margin-bottom: 16px;
                text-align:left
            }
        }
        .logo{
            line-height: 50px;
            text-align: left;
            font-size: 18px;
            img{
                height: 40px;
                vertical-align: middle;
            }
            span{
                margin-left: 5px;
            }
        }

        .weather{
            .userName{
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 240px;
                float: right;
                margin-right: 15px;
            }
            .date{
                display: inline-block;
                min-width: 130px;
                float: right;
            }
            .logout{
                margin-left: 15px;
            }
            .setting{
                margin-left: 15px;
                font-size: 18px;
                float: right;
                margin-top: 15px;
            }
            .setting:hover{
                color:#1890FF;
            }
        }
        .weather:after{
            content: '';
            display: block;
            clear: both;
        }
    }
}
.simple-page{
    .header-top{
        background:#1890ff;
        color:#ffffff;
    }
}
// 下拉设置样式
.settingDropDown {
    .ant-dropdown-menu{
        margin-top: 12px
    }
    .MenuItemIcon{
        margin-left: 10px;
    }
    .MenuItemI{
        margin-left: -3px;
    }
};
