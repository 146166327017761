@font-face {
  font-family: "trkj-iconfont"; /* Project id 1186012 */
  src: url('iconfont.woff2?t=1647771182602') format('woff2'),
       url('iconfont.woff?t=1647771182602') format('woff'),
       url('iconfont.ttf?t=1647771182602') format('truetype');
}

.trkj-iconfont {
  font-family: "trkj-iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.trkj-jiechu:before {
  content: "\e614";
}

.trkj-bangding:before {
  content: "\e615";
}

.trkj-banjiguanli:before {
  content: "\e62d";
}

.trkj-withdraw:before {
  content: "\e603";
}

.trkj-fabu:before {
  content: "\e628";
}

.trkj-yonghu:before {
  content: "\e63f";
}

.trkj-kecheng:before {
  content: "\e609";
}

.trkj-ziyuan:before {
  content: "\e612";
}

.trkj-lianjieguanlian:before {
  content: "\e77c";
}

.trkj-shouquan:before {
  content: "\e613";
}

.trkj-xiugaimima01:before {
  content: "\e606";
}

.trkj-shanchu:before {
  content: "\e686";
}

.trkj-yanjing:before {
  content: "\e600";
}

.trkj-chakan:before {
  content: "\e6ad";
}

.trkj-xiugai:before {
  content: "\e627";
}

