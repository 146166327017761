
// table页样式调整
.ty-table,
.institution,
.school,
.classes,
.sysConfig,
.applicationGroup,
.application,
.userMan,
.privilegeClass,
.privilegeRole,
.privilegeRoleGroup,
.privilegeMan,
.associatedUsers,
.classTeacherUser,
.resourceMan,
.resourceClass,
.courseClass,
.subjectMan,
.testPaperMan,
.courseMan,
.statistics
{
  background-color: #fff;
  .institutionTable
  {
    .ant-table-thead{
      background-color: #eeeeee;
    }
  }
  // 搜索
  .searchForm{
    padding:8px 0;
    margin-left: 12px;
    .formSelect{
      width: 240px;
    }
    .formInput{
      width: 220px;
    }
    .formTreeSelect{
      width: 240px;
    }
    .formButton{
      .fromBtn{
        margin-left: 10px;
      }
    }
    .createBtn{
      height: 30px;
    }
    .searchRightBtn{
      margin-right: 15px;
    }
  }
}

// modal页样式调整
.institutionModal,
.schoolModal,
.classesModal,
.sysConfigModal,
.applicationGroupModal,
.applicationModal,
.UserManModal,
.privilegeClassModal,
.privilegeRoleModal,
.privilegeManModal,
.privilegeRoleGroupModal,
.resourceManModal,
.resourceClassModal,
.courseClassModal,
.subjectManModal,
.testPaperManModal,
.courseModal
{
  .ant-modal-content{
    .ant-modal-body{
      overflow-y: auto;
      max-height: 65vh;
      margin-right: 2px;
    }
  }
  .ant-form-item{
    //margin-bottom: 12px;
  }
}

.associatedUsers{
  .searchForm {
    margin-left: 0;
  }
}
// 重置 antd treeSelect  图标样式
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon{
  font-size: 16px!important;
}
